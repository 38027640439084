import React, { useMemo } from 'react';

import { Dialog as MuiDialog } from '@mui/material';

import useModal from './hooks/useModal';
import { ModalVariant, ModalVariants } from './types';

export interface Props {
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  mode?: ModalVariant;
  triggerEl:
    | React.ReactElement
    | (({ onClick }: { onClick: () => void }) => React.ReactElement);
  children:
    | React.ReactElement
    | (({ onDialogClose }: { onDialogClose: () => void }) => React.ReactElement);
  disableEscapeKeyDown?: boolean;
  testId?: string;

  onClose?(): void;
}

export interface DialogContextProps {
  mode: ModalVariant;
}

export const DialogContext = React.createContext<DialogContextProps>({
  mode: ModalVariants.primary,
});

const Dialog: React.FC<Props> = ({
  width,
  minWidth = width || 300,
  maxWidth = width || 750,
  mode = ModalVariants.primary,
  triggerEl,
  children,
  disableEscapeKeyDown,
  testId,
  onClose,
}) => {
  const { open, handleOpen, handleClose } = useModal();

  const onCloseHandler = React.useCallback(() => {
    handleClose();
    onClose?.();
  }, [handleClose, onClose]);

  const renderedTriggerEl = useMemo(
    () =>
      typeof triggerEl === 'function'
        ? triggerEl({ onClick: handleOpen })
        : React.cloneElement(triggerEl, {
            open,
            onClick: () => {
              triggerEl.props.onClick?.();
              handleOpen();
            },
          }),
    [triggerEl]
  );

  return (
    <>
      <DialogContext.Provider value={{ mode }}>
        {renderedTriggerEl}
        <MuiDialog
          // ts doesn't recognize data-* prop as valid, but it works
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          PaperProps={{ 'data-testid': testId }}
          open={open}
          disableEscapeKeyDown={disableEscapeKeyDown}
          scroll="paper"
          sx={{
            '& .MuiDialog-paperScrollPaper': {
              ...(width && { width }),
              ...(minWidth && { minWidth }),
              ...(maxWidth && { maxWidth }),
              maxHeight: 'calc(100% - 124px)',
            },
          }}
          onClose={onCloseHandler}
          onKeyDown={(e) => e.stopPropagation()}
        >
          {typeof children === 'function'
            ? children({
                onDialogClose: onCloseHandler,
              })
            : React.cloneElement(children, {
                onDialogClose: onCloseHandler,
              })}
        </MuiDialog>
      </DialogContext.Provider>
    </>
  );
};

export { Dialog };

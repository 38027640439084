import React from 'react';
import { useIntl } from 'react-intl';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Box from '@mui/material/Box';

import { convertSxToThemeType } from 'utils';

import { brandSuitabilityExclusionType } from './constants';
import { SetExclusionsInfoModal } from './SetExclusionsInfoModal';
import { styles } from './styles';

import { Option } from '../form/fields/select-field/SelectField';

interface Props {
  value: string;
  isChildrenDisplayed: boolean;
  isGambling: boolean;
  onChange: (value: string) => void;
}

export const BrandSuitabilityForm: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  value,
  isChildrenDisplayed,
  isGambling,
  onChange,
}) => {
  const intl = useIntl();
  const radioClasses = convertSxToThemeType([
    isGambling && styles.disabled,
    { display: 'grid' },
  ]);
  const options = [
    {
      label: intl.formatMessage({ id: 'brandSuitability.form.options.default' }),
      value: brandSuitabilityExclusionType.DEFAULT,
      disabled: isGambling,
    },
    {
      label: intl.formatMessage({ id: 'brandSuitability.form.set' }),
      value: brandSuitabilityExclusionType.SET,
    },
  ] as Option[];

  return (
    <Box width="100%">
      <Box>
        <RadioGroup
          sx={radioClasses}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        >
          {options.map((option, idx) => (
            <Box display="flex">
              <FormControlLabel
                key={idx}
                value={option.value}
                control={<Radio color="primary" />}
                label={option.label}
              />
              {option.value === brandSuitabilityExclusionType.SET && (
                <SetExclusionsInfoModal />
              )}
            </Box>
          ))}
        </RadioGroup>
      </Box>
      {isChildrenDisplayed && children}
    </Box>
  );
};

import { themeColors } from 'theme/themeColors';

export const styles = {
  editIcon: {
    '&:not(.Mui-disabled) path': {
      fill: themeColors.purplePrimary,
    },
    '&.Mui-disabled path': {
      fill: themeColors.greyPrimary,
    },
    width: 48,
    height: 48,
  },
  inExcludeLabel: {
    maxWidth: '100%',
    background: themeColors.grey20,
    borderRadius: '12px',
    paddingX: 1,
    mr: 1,
    mb: 1,
    display: 'flex',
    alignItems: 'center',
  },
  inExcludeLabelText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 300,
  },
};

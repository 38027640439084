import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Typography } from '@mui/material';

import { InfoRow } from 'components/elements/info-row';
import { InfoModal } from 'components/info-modal';

export const SetExclusionsInfoModal = () => {
  const intl = useIntl();

  return (
    <InfoModal>
      <Box width={495}>
        <InfoRow
          title={intl.formatMessage({ id: 'brandSuitability.set.info.title' })}
          description={intl.formatMessage({
            id: 'brandSuitability.set.info.description',
          })}
        />
        <Box mt={2}>
          <Typography fontSize="14px" fontWeight="bold">
            <FormattedMessage id="brandSuitability.set.info.medium.title" />
          </Typography>

          <Typography fontSize="14px" lineHeight="18px">
            <FormattedMessage id="brandSuitability.set.info.medium.description" />
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography fontSize="14px" fontWeight="bold">
            <FormattedMessage id="brandSuitability.set.info.low.title" />
          </Typography>
          <Typography fontSize="14px" lineHeight="18px">
            <FormattedMessage id="brandSuitability.set.info.low.description" />
          </Typography>
        </Box>
      </Box>
    </InfoModal>
  );
};

import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { deleteAdvertiser, getAdvertiser } from '../../api/advertisers';
import { useEditAdvertiser } from '../../api/advertisers/hooks/useEditAdvertiser';
import { Advertiser } from '../../api/advertisers/types';
import { queryKeys } from '../../api/queryKeys';
import { Icon } from '../../components/icon';
import { LoadingOverlay } from '../../components/loading-overlay';
import { ManageAdvertiserForm } from '../../components/manage-advertiser-form/ManageAdvertiserForm';
import {
  AdvertiserState,
  AgencyState,
  useAdvertiserNavigationTrace,
} from '../../components/manage-advertiser-form/useAdvertiserNavigationTrace';
import { SectionTitle } from '../../components/section-title';
import { NotificationMessage, useNotification } from '../../notifications';
import { appRoutes } from '../../routes/appRoutes';

export const EditAdvertiser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: advertiser, isLoading } = useQuery(
    [queryKeys.getAdvertiser, id],
    getAdvertiser
  );
  const sendNotification = useNotification();
  const { state, navigateToPreviousPage } =
    useAdvertiserNavigationTrace<AdvertiserState>();
  const { mutate: editAdvertiser } = useEditAdvertiser();
  const onSave = useCallback(
    async (updatedAdvertiser: Advertiser) => {
      editAdvertiser(updatedAdvertiser, {
        onSuccess: () => {
          sendNotification({
            type: 'success',
            message: (
              <NotificationMessage id={`advertiser.form.createAdvertiser.success`} />
            ),
          });

          navigateToPreviousPage(
            state.navigationTrace,
            appRoutes.agencies(),
            (navState) => {
              // Update the advertiser in the agency list so we don't have to refetch and
              // throw away any other changes
              const agencyState = navState as AgencyState;
              const advertisers = [...(agencyState.agency.advertisers ?? [])];

              if (typeof state?.nextState?.index === 'number') {
                advertisers.splice(state.nextState.index, 1, updatedAdvertiser);
              } else {
                advertisers.push(updatedAdvertiser);
              }

              return {
                agency: {
                  ...agencyState.agency,
                  advertisers,
                },
              };
            }
          );
        },
      });
    },
    [state, navigateToPreviousPage, sendNotification, editAdvertiser]
  );
  const onDelete = async (deletedAdvertiser: Advertiser) => {
    await deleteAdvertiser(deletedAdvertiser.id);

    sendNotification({
      type: 'success',
      message: <NotificationMessage id={'advertiser.form.editAdvertiser.deleted'} />,
    });

    navigateToPreviousPage(state.navigationTrace, appRoutes.agencies(), (navState) => {
      const agencyState = navState as AgencyState;

      return {
        agency: {
          ...agencyState.agency,
          advertisers: agencyState.agency.advertisers?.filter(
            (a) => a.id !== deletedAdvertiser.id
          ),
        },
      };
    });
  };

  return (
    <Grid container justifyContent="space-between" spacing={10}>
      <Grid item md={8}>
        <SectionTitle
          title={<FormattedMessage id="advertiser.edit.title" />}
          description={<FormattedMessage id="advertiser.edit.description" />}
          sx={{ marginBottom: '32px' }}
        />
        {isLoading ? (
          <LoadingOverlay />
        ) : (
          <ManageAdvertiserForm
            isDeleteEnabled={true}
            advertiserToEdit={advertiser!}
            messageType={'editAdvertiser'}
            onSave={onSave}
            onDelete={onDelete}
          />
        )}
      </Grid>
      <Grid item xs={4} mt={12}>
        <Icon name="io-man" viewBox="0 0 800 1162" height={1000} />
      </Grid>
    </Grid>
  );
};

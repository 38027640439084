import { QueryFunctionContext } from 'react-query';

import { GetAudiencesResult } from './types';

import { Api } from '../api';
import { apiEndpoints } from '../endpoints';

export async function getAudiences({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<GetAudiencesResult> {
  const [, advertiserId] = queryKey;
  const { data } = await Api.get<GetAudiencesResult>(
    apiEndpoints.datalabGetAudiences({}, { advertiserId })
  );

  return data;
}

export async function getShareKeyAudiences({
  shareKey,
}: {
  shareKey: string;
}): Promise<GetAudiencesResult> {
  const { data } = await Api.get<GetAudiencesResult>(
    apiEndpoints.datalabGetAudiences({}, { shareKey })
  );

  return data;
}

import { Components, Theme } from '@mui/material';

import { themeColors } from './themeColors';

export const designButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: {
      textTransform: 'none',
      fontSize: '16px',
      minWidth: '44px',
      height: '48px',
      padding: '15px',
      fontFamily: 'Proxima Nova Bold',
      boxShadow: 'none',
      borderRadius: '3px',
      '&.Mui-disabled .MuiButton-startIcon': {
        opacity: '1 !important',
      },
      '&:hover': {
        boxShadow: 'none',
      },
      '.MuiButton-endIcon': {
        marginRight: -5,
      },
      '.MuiButton-startIcon': {
        marginLeft: -5,
      },
    },
    containedPrimary: {
      color: themeColors.white,
      '&.dropdown': {
        minWidth: 220,
        paddingRight: '12px',
        '&.open': {
          '& svg': {
            transform: 'rotate(180deg)',
            transition: 'transform 0.2s ease',
          },
        },
        '& .MuiButton-endIcon': {
          position: 'relative',
          marginRight: '2px',
          '& svg, g, path': {
            fill: themeColors.white,
            color: themeColors.white,
          },
          '&:after': {
            content: '""',
            width: '1px',
            background: themeColors.white,
            position: 'absolute',
            inset: '-12px 0 -12px -14px',
          },
        },
      },
      '&.Mui-disabled': {
        background: themeColors.greyPrimary,
      },
    },

    containedSecondary: {
      color: themeColors.purplePrimary,
      backgroundColor: themeColors.purple20,
      '& svg, g, path': {
        fill: themeColors.purplePrimary,
      },
      '&:hover': {
        backgroundColor: themeColors.purple40,
      },
      '&.Mui-disabled': {
        background: themeColors.grey20,
        color: themeColors.greyPrimary,
        '& svg, g, path': {
          fill: themeColors.greyPrimary,
        },
      },
    },
    containedError: {
      color: themeColors.white,
      '.MuiButton-endIcon, .MuiButton-startIcon': {
        '& svg, g, path': {
          fill: themeColors.white,
          color: themeColors.white,
        },
      },
      backgroundColor: themeColors.red,
      '&:hover': {
        backgroundColor: themeColors.redHover,
      },
    },
    outlined: {
      backgroundColor: themeColors.white,
      border: `solid 1px ${themeColors.purplePrimary}`,
      '&:hover': {
        color: themeColors.purpleHover,
        backgroundColor: themeColors.white,
        border: `solid 1px ${themeColors.purpleHover}`,
        '& svg:not(.hover-disabled), svg:not(.hover-disabled)g, svg:not(.hover-disabled)path':
          {
            fill: themeColors.purpleHover,
          },
      },
      '&.Mui-disabled': {
        color: themeColors.greyPrimary,
        border: `solid 1px ${themeColors.greyPrimary}`,
        '& svg, g, path': {
          fill: themeColors.greyPrimary,
        },
      },
      '&.Mui-disabled .MuiButton-endIcon': {
        '&:after': {
          background: themeColors.greyPrimary,
        },
        '& svg, g, path': {
          fill: themeColors.greyPrimary,
        },
      },
      '&.dropdown': {
        justifyContent: 'space-between',
        paddingRight: '12px',
        minWidth: 160,
        '&.open': {
          '& svg': {
            transform: 'rotate(180deg)',
            transition: 'transform 0.2s ease',
          },
        },
        '& .MuiButton-endIcon': {
          position: 'relative',
          marginRight: '2px',
          '& svg, g, path': {
            fill: themeColors.purplePrimary,
          },
          '&:after': {
            content: '""',
            width: '1px',
            background: themeColors.purplePrimary,
            position: 'absolute',
            inset: '-12px 0 -12px -14px',
          },
        },
        '&.Mui-disabled .MuiButton-endIcon': {
          '&:after': {
            background: themeColors.greyPrimary,
          },
          '& svg, g, path': {
            fill: themeColors.greyPrimary,
          },
        },
      },
    },
    text: {
      fontFamily: 'Proxima Nova Semibold',
      '&:hover': {
        color: themeColors.purpleHover,
      },
      '&.secondary': {
        color: themeColors.grey120,
        '& a, & span': {
          color: themeColors.grey120,
        },
      },
      '&.primary': {
        color: themeColors.purplePrimary,
        '& a, & span': {
          color: themeColors.purplePrimary,
          fontWeight: 'normal',
        },
      },
      '& a': {
        textDecoration: 'none',
      },
      '&.Mui-disabled': {
        color: themeColors.greyPrimary,
      },
    },
    textSecondary: {
      color: themeColors.grey120,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& a': {
        color: themeColors.grey120,
        textDecoration: 'none',
      },
    },
  },
};

import { DatalabAudience } from '../../../api/datalab/types';
import {
  DatalabAudienceSelection,
  DatalabAudienceSelectionRelation,
} from '../../../api/deals/types';
import { ExtendedSelectData } from '../../form/fields/autocomplete-tags/AutocompleteTagsLocal';

export const getFilterValue = (
  audiences: DatalabAudienceSelection[]
): {
  include: DatalabAudience[];
  exclude: DatalabAudience[];
} => {
  const selectedIncludedValues = audiences
    .filter((a) => a.relation === DatalabAudienceSelectionRelation.INCLUDE)
    .map((a) => a.audience);

  const selectedExcludedValues = audiences
    .filter((a) => a.relation === DatalabAudienceSelectionRelation.EXCLUDE)
    .map((a) => a.audience);

  return {
    include: selectedIncludedValues,
    exclude: selectedExcludedValues,
  };
};

export const getAudienceValues = (
  audiences: DatalabAudienceSelection[],
  relation: DatalabAudienceSelectionRelation
): ExtendedSelectData<DatalabAudienceSelection>[] => {
  return audiences
    .filter((a) => a.relation === relation)
    .map((a) => ({
      label: a.audience.name,
      value: a.audience.id,
      properties: a,
      key: a.audience.id,
    }));
};

export const getAudienceOptions = (
  audiences: DatalabAudience[],
  currentAudiences: DatalabAudienceSelection[],
  relation: DatalabAudienceSelectionRelation
): ExtendedSelectData<DatalabAudienceSelection>[] => {
  return audiences.map((a) => {
    const current = currentAudiences.find((ca) => ca.audience.id === a.id);

    return {
      label: a.name,
      value: a.id,
      properties: {
        audience: a,
        relation,
      },
      id: a.id,
      key: a.id,
      included:
        relation === DatalabAudienceSelectionRelation.EXCLUDE &&
        current?.relation === DatalabAudienceSelectionRelation.INCLUDE,
      excluded:
        relation === DatalabAudienceSelectionRelation.INCLUDE &&
        current?.relation === DatalabAudienceSelectionRelation.EXCLUDE,
      disabled: current && current.relation !== relation,
      selected: current && current.relation === relation,
    };
  });
};

export const setAudiencesForRelation = (
  audiencesToSet: ExtendedSelectData<DatalabAudienceSelection>[],
  currentAudiences: DatalabAudienceSelection[],
  relation: DatalabAudienceSelectionRelation
): DatalabAudienceSelection[] => {
  return currentAudiences
    .filter((as) => as.relation !== relation)
    .concat(audiencesToSet.map((s) => s.properties!));
};

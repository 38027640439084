import React from 'react';
import { useIntl } from 'react-intl';

import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  SxProps,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';

import { convertSxToThemeType } from 'utils';

import { styles } from './styles';

import { Option } from '../select-field/SelectField';

export type Props = Partial<
  Omit<AutocompleteProps<Option, boolean, boolean, boolean>, 'label'>
> & {
  testId?: string;
  label?: string;
  options: Option[];
  placeholder?: string;
};

const AutocompleteComponent: React.FC<React.PropsWithChildren<Props>> = ({
  options,
  testId = 'autocomplete',
  placeholder = '',
  sx = {},
  ...props
}) => {
  const intl = useIntl();
  const sxStyles = convertSxToThemeType([
    styles.autoSelectPopper,
    styles.autoSelectOption,
    styles.popupIndicator,
    sx as SxProps,
  ]);

  return (
    <MuiAutocomplete
      fullWidth
      data-testid={testId}
      clearIcon={null}
      sx={sxStyles}
      options={options}
      getOptionLabel={(option: unknown) => (option as Option).label as string}
      renderOption={(option: unknown) => (
        <Box sx={styles.autoSelectOptionItem}>{(option as Option).label}</Box>
      )}
      renderInput={(params) => (
        <Box>
          <TextField
            {...params}
            variant="outlined"
            placeholder={intl.formatMessage({
              id: placeholder,
            })}
          />
        </Box>
      )}
      {...props}
    />
  );
};

const AutocompleteLocal = React.memo(AutocompleteComponent);

export default AutocompleteLocal;

import React from 'react';

import { DialogContainer, DialogFooter, DialogTitle } from 'components/dialog2';
import { Reason } from 'components/dialog2/types';

import { Browse } from './components/browse/Browse';
import { TEST_ID } from './constants';
import { DetailedTargeting } from './types';

interface Props {
  data?: DetailedTargeting;
  value: DetailedTargeting;
  onChange: (value: DetailedTargeting) => void;
  onSubmit: () => void;
  onCancel: () => void;

  onDialogClose?(event?: Record<string, unknown>, reason?: Reason): void;
}

const DetailedTargetingFilter: React.FC<Props> = ({
  data,
  value,
  onChange,
  onSubmit,
  onCancel,
  onDialogClose,
}) => {
  const handleSubmit = () => {
    onSubmit();
    onDialogClose?.();
  };
  const handleCancel = () => {
    onCancel();
    onDialogClose?.();
  };

  if (!data) {
    return null;
  }

  return (
    <DialogContainer
      header={
        <DialogTitle
          testId={TEST_ID.targetingFilter}
          headerLabel="deal.targeting.dialog.title"
          subHeaderLabel="deal.targeting.dialog.description"
          handleClose={handleCancel}
        />
      }
      footer={
        <DialogFooter
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          submitButtonLabel="deal.targeting.dialog.submit"
        />
      }
    >
      <Browse data={data} value={value} onChange={onChange} />
    </DialogContainer>
  );
};

export { DetailedTargetingFilter };

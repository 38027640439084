import React, { Dispatch, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Checkbox, Grid, ListItem } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import Box from '@mui/material/Box';
import { FilterOptionsState } from '@mui/material/useAutocomplete';

import AutocompleteLocal from 'components/form/fields/autocomplete/AutocompleteLocal';
import { TextOverflowEllipsis } from 'components/text-overflow-ellipsis';
import { convertSxToThemeType } from 'utils';

import { Option } from './components/option/Option';
import { Tags } from './components/tags/Tags';
import { LIMIT_TAGS_COUNT, SELECT_ALL_ID } from './constants';
import { styles } from './styles';

import { Option as SelectOption } from '../select-field/SelectField';

export interface ExtendedSelectData<P = unknown> extends SelectOption {
  included?: boolean;
  excluded?: boolean;
  id?: string;
  properties?: P;
}

interface Props<P> {
  options: ExtendedSelectData<P>[];
  placeholder?: string;
  selectAll?: boolean;
  disabled?: boolean;
  selectAllLabel?: string;
  withCheckboxes?: boolean;
  withDisplayedValue?: boolean;
  minSelected?: number;
  value: SelectOption[];
  withDisplayedType?: boolean;
  setValue: Dispatch<SelectOption[] | []>;
  optionsRenderer?: (props: unknown) => React.ReactElement;
  tagLabelRenderer?: (props: unknown) => React.ReactElement;
}

export function AutocompleteTagsLocal<P>({
  options: initialOptions,
  placeholder,
  selectAll = false,
  disabled,
  selectAllLabel,
  withCheckboxes,
  withDisplayedValue = true,
  withDisplayedType = true,
  minSelected,
  value,
  setValue,
  optionsRenderer: customOptionsRenderer,
  tagLabelRenderer,
  ...props
}: Props<P>) {
  const options = useMemo(() => {
    if (minSelected && value?.length <= minSelected) {
      return initialOptions.map((initialOption: SelectOption) => ({
        ...initialOption,
        disabled: value.find(({ value }: SelectOption) => value === initialOption.value),
      }));
    }

    return initialOptions;
  }, [minSelected, value, initialOptions]);

  const isAllOptionSelected = useMemo(
    () =>
      selectAllLabel ? options.length > 0 && options.length === value.length : false,
    [options.length, selectAllLabel, value.length]
  );
  const handleClearOptions = useCallback(() => setValue([]), [setValue]);

  const handleSelectAll = useCallback(
    (isSelected: boolean) => {
      if (isSelected) {
        setValue(options as SelectOption[]);
      } else {
        handleClearOptions();
      }
    },
    [setValue, options, handleClearOptions]
  );

  const handleChange = useCallback(
    (event: React.SyntheticEvent, selectedOptions: SelectOption[], reason: string) => {
      if (reason === 'selectOption' || reason === 'removeOption') {
        if (
          selectedOptions.find((option: SelectOption) => option.value === SELECT_ALL_ID)
        ) {
          handleSelectAll(!isAllOptionSelected);
        } else {
          setValue(selectedOptions);
        }
      } else if (reason === 'clear') {
        handleClearOptions();
      }
    },
    [isAllOptionSelected, handleClearOptions, handleSelectAll, setValue]
  );

  const getOptionSelected = useCallback(
    (option: SelectOption, anotherOption: SelectOption) =>
      option.value === anotherOption.value,
    []
  );

  const onTagDelete = useCallback(
    (index: number) => {
      if (index === -1) {
        return setValue([]);
      }
      const selectedOptions = [...value];
      selectedOptions.splice(index, 1);
      setValue(selectedOptions);
    },
    [setValue, value]
  );

  const optionsRenderer = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: ExtendedSelectData<P>,
      { selected, inputValue }: AutocompleteRenderOptionState
    ) =>
      withCheckboxes ? (
        <ListItem {...props} key={(option?.id || option.value) as string}>
          <Grid container sx={styles.autoSelectCheckboxOptionItem} alignItems="center">
            <Grid item xs={1}>
              <Checkbox
                style={{ marginRight: 8 }}
                checked={selected}
                disabled={!!option.disabled}
                color="primary"
              />
            </Grid>
            <Grid item xs={9}>
              <TextOverflowEllipsis>{option.label}</TextOverflowEllipsis>
            </Grid>
            {withDisplayedValue && (
              <Grid item xs={1} sx={styles.optionSubtitle}>
                {option.value as string}
              </Grid>
            )}
          </Grid>
        </ListItem>
      ) : (
        <ListItem
          {...props}
          key={(option?.id || option.value) as string}
          sx={styles.defaultOption}
        >
          {customOptionsRenderer ? (
            customOptionsRenderer({ option, selected, inputValue })
          ) : (
            <Option
              option={option}
              inputValue={inputValue}
              isAllOptionSelected={isAllOptionSelected}
            />
          )}
        </ListItem>
      ),
    [withCheckboxes, withDisplayedValue, customOptionsRenderer, isAllOptionSelected]
  );

  const tagsRenderer = useCallback(
    () => (
      <Tags
        isAllOptionSelected={isAllOptionSelected}
        selectAllLabel={selectAllLabel as string}
        tags={value}
        disabled={Boolean(minSelected && value?.length <= minSelected)}
        tagLabelRenderer={tagLabelRenderer}
        onTagDelete={onTagDelete}
      />
    ),
    [
      isAllOptionSelected,
      selectAllLabel,
      value,
      minSelected,
      tagLabelRenderer,
      onTagDelete,
    ]
  );

  const optionsFilter = useCallback(
    (options: SelectOption[], state: FilterOptionsState<SelectOption>) => {
      const filtered = createFilterOptions()(
        options,
        state as FilterOptionsState<unknown>
      ) as SelectOption[];

      if (!selectAll || state.inputValue?.length > 0) {
        return filtered;
      }

      return [{ label: selectAllLabel || '', value: SELECT_ALL_ID }, ...filtered];
    },
    [selectAll, selectAllLabel]
  );

  const disabledOptions = ({ disabled }: SelectOption) => Boolean(disabled);

  return (
    <Box style={{ width: '100%' }}>
      <Box>{tagsRenderer()}</Box>
      <AutocompleteLocal
        multiple
        disableCloseOnSelect
        limitTags={LIMIT_TAGS_COUNT}
        options={options as SelectOption[]}
        value={value}
        disabled={disabled}
        sx={convertSxToThemeType([
          styles.paper,
          styles.listbox,
          styles.inputRoot,
          styles.popper,
          styles.option,
        ])}
        placeholder={placeholder}
        noOptionsText={<FormattedMessage id="results.no_options" />}
        renderOption={optionsRenderer}
        filterOptions={optionsFilter}
        isOptionEqualToValue={getOptionSelected}
        renderTags={() => null}
        getOptionDisabled={disabledOptions}
        // eslint-disable-next-line
        onChange={handleChange as any}
        {...props}
      />
    </Box>
  );
}

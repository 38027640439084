import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import { InlineAlert } from 'components/inline-alert';
import { themeColors } from 'theme/themeColors';

import { DatalabAudienceInfo } from './DatalabAudienceInfo';
import { Loader } from './Loader';
import { RemoveDatalabAudience } from './RemoveDatalabAudience';
import { useShareKeyAudiences } from './useShareKeyAudiences';
import { ErrorCodes, ErrorResponse, mapErrorCodeToError } from './utils';

import { DatalabAudienceGroup } from '../../api/datalab/types';
import { useAbility } from '../acl/AclProvider';
import { Actions, Permissions } from '../acl/constants';
import { Button } from '../button';
import { TextField } from '../form/fields/text-field/TextField';

interface Props {
  audienceGroup: DatalabAudienceGroup;
  onRemove: () => void;
}

export const DatalabAudience: React.FC<Props> = ({ audienceGroup, onRemove }) => {
  const intl = useIntl();
  const ability = useAbility();

  const canManage = ability.can(Actions.Manage, Permissions.DatalabAudiences);

  const { data, isLoading, refetch } = useShareKeyAudiences(audienceGroup.shareKey, {
    onSuccess: () => {
      setErrorCode(null);
    },
    onError: (error) => {
      setErrorCode(
        ((error as AxiosError).response?.data as ErrorResponse)?.code ??
          ErrorCodes.UNKNOWN
      );
    },
  });
  const [errorCode, setErrorCode] = useState<ErrorCodes | null>(null);

  return (
    <Grid container mb={3} borderLeft={`4px solid ${themeColors.grey20}`} pl={2}>
      <>
        <Grid item width={170} pt={2}>
          <Typography variant="body1">
            <FormattedMessage id="advertiser.new.datalab.audiences.row.title" />
          </Typography>
        </Grid>
        <Grid item xs>
          <TextField
            fullWidth
            value={audienceGroup.shareKey}
            variant="outlined"
            disabled={true}
          />
        </Grid>
        <Grid item xs={0.5} padding={2}>
          {canManage && (
            <RemoveDatalabAudience
              datalabAudiences={data?.audiences ?? []}
              onRemove={onRemove}
            />
          )}
        </Grid>
      </>
      {isLoading && <Loader />}
      {!isLoading && !errorCode && data && (
        <DatalabAudienceInfo
          datalabAudiences={data.audiences}
          containerProps={{ pl: '170px' }}
        />
      )}
      {!isLoading && errorCode !== null && (
        <Grid item xs={11.5} mt={2}>
          <InlineAlert severity="error">
            {mapErrorCodeToError(intl, errorCode)}
            {(errorCode === ErrorCodes.CONNECTION_ERROR ||
              errorCode === ErrorCodes.UNKNOWN) && (
              <>
                <br />
                <Button
                  variant="text"
                  size="small"
                  sx={{ marginTop: '8px' }}
                  onClick={(event) => {
                    event.preventDefault();
                    refetch();
                  }}
                >
                  <FormattedMessage id="error.try.again" />
                </Button>
              </>
            )}
          </InlineAlert>
        </Grid>
      )}
    </Grid>
  );
};

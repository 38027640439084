import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';

export const Loader: React.FC = () => (
  <Grid item container xs={12} mt={2} alignItems="center">
    <CircularProgress color="primary" size={18} sx={{ mr: 1 }} />
    <FormattedMessage id="advertiser.new.datalab.audiences.loading" />
  </Grid>
);

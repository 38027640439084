import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box } from '@mui/material';

import DatalabIcon from 'assets/icons/datalab/DatalabIcon';
import { useItemsOverflow } from 'components/items-overflow/useItemsOverflow';
import { TextOverflowTooltip } from 'components/text-overflow-tooltip';

import { styles } from './styles';
import { getFilterValue } from './utils';

import { DatalabAudience } from '../../../api/datalab/types';
import { DatalabAudienceSelection } from '../../../api/deals/types';

interface Props {
  datalabAudiences?: DatalabAudienceSelection[] | null;
}

export const InExcludeLabel: React.FC<Props> = ({ datalabAudiences = [] }) => {
  const intl = useIntl();
  const { include, exclude } = React.useMemo(
    () => getFilterValue(datalabAudiences ?? []),
    [datalabAudiences]
  );

  const { itemsToRender: includeItemsToRender, expandButton: includeExpandButton } =
    useItemsOverflow({
      items: include,
      maxItemsCount: 4,
    });
  const { itemsToRender: excludeItemsToRender, expandButton: excludeExpandButton } =
    useItemsOverflow({
      items: exclude,
      maxItemsCount: 4,
    });

  const isIncludeValueExist = !!include.length;
  const isExcludeValueExist = !!exclude.length;

  const itemRenderer = useCallback(
    (item: DatalabAudience) => {
      const audienceName = `${intl.formatMessage({
        id: `audience.datalab.type.${item.type}`,
      })} ${item.name}`;

      return (
        <Box key={item.id} sx={styles.inExcludeLabel}>
          <Box sx={styles.inExcludeLabelText}>
            <TextOverflowTooltip value={audienceName as string} />
          </Box>
          <DatalabIcon sx={{ ml: 1, width: '15px' }} />
        </Box>
      );
    },
    [intl]
  );

  return (
    <Box>
      {!isIncludeValueExist && !isExcludeValueExist && (
        <FormattedMessage id="deal.form.item.datalabAudiences.default" />
      )}
      {isIncludeValueExist && (
        <Box display="flex" pt={2}>
          <Box mr={2}>
            <FormattedMessage id="deal.form.item.datalabAudiences.filters.include" />:
          </Box>
          <Box display="flex" flexWrap="wrap">
            {includeItemsToRender.map((item) => itemRenderer(item))}
            {includeExpandButton}
          </Box>
        </Box>
      )}
      {isExcludeValueExist && (
        <Box display="flex">
          <Box mr={2}>
            <FormattedMessage id="deal.form.item.datalabAudiences.filters.exclude" />:
          </Box>
          <Box display="flex" flexWrap="wrap">
            {excludeItemsToRender.map((item) => itemRenderer(item))}
            {excludeExpandButton}
          </Box>
        </Box>
      )}
    </Box>
  );
};

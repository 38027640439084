import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Dialog, DialogContent, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { Button } from 'components/button';

import { DatalabAudience } from '../../api/datalab/types';
import { themeColors } from '../../theme/themeColors';
import { Icon } from '../icon';

interface Props {
  datalabAudiences: DatalabAudience[];
  onRemove: () => void;
}

export const RemoveDatalabAudience: React.FC<Props> = ({
  datalabAudiences,
  onRemove,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const isNoUsage = datalabAudiences.length === 0;

  const handleClose = (_: React.MouseEvent, reason: string) => {
    if (reason !== 'backdropClick') {
      setDialogOpen(false);
    }
  };
  const onCancelHandler = () => {
    setDialogOpen(false);
  };
  const onConfirmHandler = () => {
    onRemove();
    onCancelHandler();
  };

  const onCrossClick = () => {
    if (isNoUsage) {
      onRemove();
      return;
    }

    setDialogOpen(true);
  };

  return (
    <>
      <Box sx={{ cursor: 'pointer' }} onClick={onCrossClick}>
        <Icon name="close" width={20} height={20} color={themeColors.purplePrimary} />
      </Box>

      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogContent sx={{ maxWidth: 480, padding: '24px 32px' }}>
          <Typography variant="h1">
            <FormattedMessage id="advertiser.new.datalab.audiences.remove.title" />
          </Typography>
          <Typography variant="body1" mt={2}>
            <FormattedMessage
              id="advertiser.new.datalab.audiences.remove.description1"
              values={{ audiences: datalabAudiences.length }}
            />
          </Typography>
          <Typography variant="body1" mt={2}>
            <FormattedMessage id="advertiser.new.datalab.audiences.remove.description2" />
          </Typography>
          <Box mt={4}>
            <Button
              labelId="advertiser.new.datalab.audiences.remove.submit"
              sx={{ mr: 3 }}
              color="error"
              onClick={onConfirmHandler}
            />
            <Button
              variant="outlined"
              labelId="advertiser.new.datalab.audiences.remove.cancel"
              onClick={onCancelHandler}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { Option as OptionType } from 'components/form/fields/select-field/SelectField';
import { convertSxToThemeType } from 'utils';

import { SELECT_ALL_ID } from '../../constants';
import { styles } from '../../styles';

interface Props {
  option: OptionType;
  isAllOptionSelected: boolean;
  inputValue: string;
}

export const Option: React.FC<Props> = ({ option, isAllOptionSelected, inputValue }) => {
  if (option.value === SELECT_ALL_ID) {
    return (
      <>
        <Box
          sx={convertSxToThemeType([
            styles.autoSelectOptionItem,
            isAllOptionSelected && styles.optionSelected,
          ])}
        >
          {option.label}
        </Box>
        <Divider sx={styles.devider} />
      </>
    );
  }

  const matches = match(option.label, inputValue);
  const parts = parse(option.label, matches);

  return (
    <Box sx={styles.autoSelectOptionItem}>
      {parts.map((part: { highlight: boolean; text: string }, index: number) => (
        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
          {part.text}
        </span>
      ))}
    </Box>
  );
};

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Grid } from '@mui/material';

import DatalabIcon from 'assets/icons/datalab/DatalabIcon';
import { StatusIcon } from 'assets/icons/status-icon/StatusIcon';
import { ExtendedSelectData } from 'components/form/fields/autocomplete-tags/AutocompleteTagsLocal';
import { themeColors } from 'theme/themeColors';

import { DatalabAudienceSelection } from '../../../api/deals/types';

interface Props {
  option: ExtendedSelectData<DatalabAudienceSelection>;
  inputValue: string;
}

export function AudienceAutocompleteOption({ option, inputValue }: Props) {
  const intl = useIntl();

  const matches = match(option.label as string, inputValue);
  const parts = parse(option.label as string, matches);
  const audience = option.properties!.audience;

  return (
    <Grid container minHeight={40}>
      <Grid item container xs={1} justifyContent="center">
        <Box display="flex" alignItems="center">
          <StatusIcon color={themeColors.greenPrimary} />
        </Box>
      </Grid>

      <Grid item xs={6} pt={1} whiteSpace="pre-wrap" sx={{ wordBreak: 'break-all' }}>
        {parts.map((part: { highlight: boolean; text: string }, index: number) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
        {option.included
          ? ` ${intl.formatMessage({ id: 'deal.datalabAudience.dialog.filters.included' })}`
          : ''}
        {option.excluded
          ? ` ${intl.formatMessage({ id: 'deal.datalabAudience.dialog.filters.excluded' })}`
          : ''}
      </Grid>

      <Grid item container xs={4} alignItems="center" pl={0}>
        <DatalabIcon sx={{ mr: 1, width: '20px' }} />
        Datalab <FormattedMessage id={`audience.datalab.type.${audience.type}`} />
      </Grid>

      <Grid item container xs={1} pt={1} justifyContent="flex-end">
        {Boolean(audience.reach) &&
          intl.formatNumber(audience.reach, {
            notation: 'compact',
          })}
      </Grid>
    </Grid>
  );
}

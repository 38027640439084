import { useQuery } from 'react-query';

import { Advertiser } from '../../../api/advertisers/types';
import { getAudiences } from '../../../api/datalab';
import { queryKeys } from '../../../api/queryKeys';

export function useAdvertiserAudiences(advertiser?: Advertiser) {
  return useQuery(
    [queryKeys.getAdvertiserAudiences, advertiser?.id ?? ''],
    getAudiences,
    {
      enabled: !!advertiser?.id,
    }
  );
}

import { theme } from '../../theme';
import { themeColors } from '../../theme/themeColors';

export const styles = {
  advertiserItem: {
    marginBottom: theme.spacing(1),
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
  },
  title: {
    color: themeColors.greyPrimary,
  },
  fullWidth: {
    width: '100%',
  },
  checkbox: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
  },
  formActionButton: {
    marginRight: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer',
  },
};

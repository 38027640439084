import React, { MouseEventHandler, ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { SxProps } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';

import { TEST_ID } from 'components/constants';
import { Icon, IconName } from 'components/icon';
import { themeColors } from 'theme/themeColors';
import { convertSxToThemeType } from 'utils';

import { styles } from './styles';

export interface Props extends ButtonProps {
  children?: ReactNode;
  testId?: string;
  variant?: ButtonProps['variant'];
  width?: number | string;
  to?: string;
  startIconName?: IconName;
  endIconName?: IconName;
  iconSx?: SxProps;
  labelId?: string;
  disabled?: boolean;
  href?: string;
  target?: string;

  onClick?: MouseEventHandler;
}

const ButtonComponent: React.FC<Props> = ({
  to,
  children,
  testId,
  type = 'submit',
  width = 'fit-content',
  className,
  startIconName,
  endIconName = className === 'dropdown' && 'dropdown-chevron',
  sx,
  iconSx,
  variant = 'contained',
  labelId,
  disabled = false,
  onClick,
  ...restProps
}) => {
  const buttonSx = convertSxToThemeType([
    styles.button,
    !!(endIconName || startIconName) && styles.buttonWithIcon,
    { width },
    sx as SxProps,
  ]);

  const btnIcon = useCallback(
    (iconName: IconName) => (
      <Icon
        name={iconName}
        width={24}
        height={24}
        color={
          !iconSx && variant === 'contained'
            ? themeColors.white
            : themeColors.purplePrimary
        }
        sx={iconSx}
      />
    ),
    [iconSx, variant]
  );

  const getDefaultButton = useCallback(
    (props?: { to: string; component: React.ReactNode }) => (
      <Button
        variant={variant as ButtonProps['variant']}
        className={className}
        type={type}
        sx={buttonSx}
        data-testid={testId || TEST_ID.nextButton}
        disabled={disabled}
        startIcon={startIconName ? btnIcon(startIconName) : null}
        endIcon={endIconName ? btnIcon(endIconName as IconName) : null}
        onClick={onClick}
        {...restProps}
        {...props}
      >
        {labelId && <FormattedMessage id={labelId} />}
        {children}
      </Button>
    ),
    [
      variant,
      className,
      type,
      buttonSx,
      testId,
      disabled,
      onClick,
      startIconName,
      btnIcon,
      endIconName,
      restProps,
      labelId,
      children,
    ]
  );

  if (to) {
    return getDefaultButton({ to, component: Link as unknown as React.ReactNode });
  }

  return getDefaultButton();
};

const GenButton = React.memo(ButtonComponent);

export { GenButton };

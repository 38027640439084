import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import { Dialog } from 'components/dialog2';
import { ModalVariants } from 'components/dialog2/types';
import { Icon } from 'components/icon';

import { TEST_ID } from './constants';
import { DetailedTargetingFilter } from './DetailedTargetingFilter';
import { FilterValueLabel } from './FilterValueLabel';
import useDetailedTargeting from './hooks/useDetailedTargeting';
import { styles } from './styles';
import { TargetingValue } from './types';
import { extendToDetailedTargeting, getFilterValue, simplifyToTargeting } from './utils';

import { Targeting } from '../../../api/deals/types';
import { InfoRow } from '../../elements/info-row';
import { RowWithTitle } from '../../elements/row-with-title';
import { FilterRow } from '../../filter-row';
import { ReadOnlyToggleField } from '../../form/fields/read-only-toggle-field';

interface Props {
  targeting: Targeting | null | undefined;
  onChange: (targeting: Targeting) => void;
  isManageable: boolean;
}

const DetailedTargetingDialog: React.FC<Props> = ({
  targeting,
  isManageable,
  onChange: onChangeTargeting,
}) => {
  const detailedTargeting = useDetailedTargeting();
  const intl = useIntl();
  const curDetailedTargeting = useMemo(
    () => extendToDetailedTargeting(targeting),
    [targeting]
  );
  const [value, onChange] = useState<TargetingValue>(curDetailedTargeting);
  const onSubmit = () => {
    onChangeTargeting(simplifyToTargeting(value));
  };
  const onCancel = () => {
    onChange(curDetailedTargeting);
  };

  const filterValue = React.useMemo(
    () => getFilterValue(detailedTargeting, value),
    [detailedTargeting, value]
  );

  return (
    <FilterRow
      title={intl.formatMessage({ id: 'deal.form.item.targeting' })}
      mt={3}
      mb={3}
      infoModalContent={
        isManageable ? (
          <Box width={450}>
            <InfoRow
              title={intl.formatMessage({
                id: 'deal.form.item.targeting.info.title',
              })}
              description={intl.formatMessage({
                id: 'deal.form.item.targeting.info.description',
              })}
            />
          </Box>
        ) : undefined
      }
    >
      <Grid container justifyContent="flex-start" alignItems="center">
        <ReadOnlyToggleField
          editable={isManageable}
          value={
            <Box display="flex" alignItems="center">
              <FilterValueLabel filterValue={filterValue} />
            </Box>
          }
        >
          <Box display="flex" alignItems="center">
            <FilterValueLabel filterValue={filterValue} />
          </Box>
          <Dialog
            mode={ModalVariants.secondary}
            testId={TEST_ID.targetingFilterModal}
            width={650}
            triggerEl={
              <IconButton
                aria-label="more"
                aria-haspopup="true"
                sx={styles.editIcon}
                size="large"
              >
                <Icon name="edit" width={24} height={24} />
              </IconButton>
            }
          >
            <DetailedTargetingFilter
              value={value}
              data={detailedTargeting}
              onChange={onChange}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          </Dialog>
        </ReadOnlyToggleField>
      </Grid>
    </FilterRow>
  );
};

export { DetailedTargetingDialog };

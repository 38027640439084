import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import DatalabIcon from 'assets/icons/datalab/DatalabIcon';
import { Button } from 'components/button';
import { InfoRow } from 'components/elements/info-row';
import { themeColors } from 'theme/themeColors';

import { DatalabAudience } from './DatalabAudience';
import { DatalabAudienceAddDialog } from './DatalabAudienceAddDialog';

import { Advertiser } from '../../api/advertisers/types';
import { DatalabAudienceGroup } from '../../api/datalab/types';
import { useAbility } from '../acl/AclProvider';
import { Actions, Permissions } from '../acl/constants';
import { InfoModal } from '../info-modal';
import { styles } from '../manage-advertiser-form/styles';

interface Props {
  advertiser: Advertiser;
  onChange: (shareKeys: Array<DatalabAudienceGroup>) => void;
}

export const DatalabAudiences: React.FC<Props> = ({ onChange, advertiser }: Props) => {
  const intl = useIntl();
  const ability = useAbility();
  const audienceGroups = useMemo(
    () => advertiser.datalab?.audienceGroups ?? [],
    [advertiser.datalab?.audienceGroups]
  );
  const handleAudienceGroupAdd = useCallback(
    (audienceGroup: DatalabAudienceGroup) => {
      onChange([...audienceGroups, audienceGroup]);
    },
    [audienceGroups, onChange]
  );
  const handleAudienceGroupRemove = useCallback(
    (index: number) => {
      onChange(audienceGroups.toSpliced(index, 1));
    },
    [audienceGroups, onChange]
  );

  const isViewable = ability.can(Actions.View, Permissions.DatalabAudiences);
  const isManageable = ability.can(Actions.Manage, Permissions.DatalabAudiences);
  const isNoValue = !audienceGroups.length;

  if (!isViewable) {
    return null;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box display="flex" sx={styles.titleContainer}>
        <Box mr={1}>
          <Typography variant="h2" sx={styles.title}>
            <FormattedMessage id="advertiser.new.datalab.audiences.title" />
          </Typography>
        </Box>
        <InfoModal>
          <Box width={565}>
            <InfoRow
              title={intl.formatMessage({ id: 'advertiser.new.datalab.audiences.title' })}
              description={intl.formatMessage(
                {
                  id: 'advertiser.new.datalab.audiences.info.description',
                },
                { newLine: <Box mt={2} /> }
              )}
            />
          </Box>
        </InfoModal>
      </Box>
      <Box mt={1}>
        {isManageable && (
          <DatalabAudienceAddDialog
            audienceGroups={audienceGroups}
            triggerEl={
              <Button
                variant="outlined"
                labelId="advertiser.new.datalab.audiences.button"
                startIcon={<DatalabIcon />}
              />
            }
            onAdd={handleAudienceGroupAdd}
          />
        )}
        {!isManageable && isNoValue && (
          <Box sx={{ backgroundColor: themeColors.grey30, padding: '50px 25px' }}>
            <Typography variant="body1">
              <FormattedMessage id="advertiser.new.datalab.audiences.no.audiences" />
            </Typography>
          </Box>
        )}
        <Box mt={3}>
          {audienceGroups.map((audienceGroup, index) => (
            <DatalabAudience
              audienceGroup={audienceGroup}
              onRemove={() => handleAudienceGroupRemove(index)}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

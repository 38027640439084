import React from 'react';

import { Box } from '@mui/material';

import { ReactFCC } from '../../react-util';

export const TextOverflowEllipsis: ReactFCC = ({ children }) => (
  <Box style={{ overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: 10 }}>
    {children}
  </Box>
);

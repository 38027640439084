import React from 'react';

import { IconButton } from '@mui/material';

import { styles } from './styles';

import { Advertiser } from '../../api/advertisers/types';
import { Agency } from '../../api/agencies/types';
import { appRoutes } from '../../routes/appRoutes';
import { useAbility } from '../acl/AclProvider';
import { Actions, Permissions } from '../acl/constants';
import { Icon } from '../icon';
import { useAdvertiserNavigationTrace } from '../manage-advertiser-form/useAdvertiserNavigationTrace';

export type Props = {
  agency: Agency;
  advertiser: Advertiser;
  index: number;
};

export const EditAdvertiserButton: React.FC<Props> = ({ agency, advertiser, index }) => {
  const { navigateToNextPage, state } = useAdvertiserNavigationTrace();
  const navTrace = state?.navigationTrace;
  const onNavigate = () => {
    navigateToNextPage(
      advertiser.id
        ? appRoutes.editAdvertiser({ id: advertiser.id })
        : appRoutes.createAdvertiser(),
      navTrace,
      window.location.pathname,
      { agency },
      { index, advertiser }
    );
  };
  const ability = useAbility();
  const canEditAdvertisers = ability.can(Actions.Manage, {
    type: Permissions.Advertisers,
    ...agency,
  });

  if (!canEditAdvertisers) {
    return null;
  }

  return (
    <IconButton sx={styles.editIcon} onClick={onNavigate}>
      <Icon name="edit" width={24} height={24} />
    </IconButton>
  );
};

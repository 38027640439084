export const styles = {
  wrapper: {
    position: 'relative',
    display: 'flex',
  },
  infoModal: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
};

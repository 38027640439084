import { SxProps } from '@mui/system';

import { themeColors } from 'theme/themeColors';

export const styles: {
  autoSelectOptionItem: SxProps;
  autoSelectCheckboxOptionItem: SxProps;
  defaultOption: SxProps;
  defaultGrid: SxProps;
  optionSelected: SxProps;
  paper: SxProps;
  inputRoot: SxProps;
  popper: SxProps;
  listbox: SxProps;
  option: SxProps;
  devider: SxProps;
  optionSubtitle: SxProps;
  disabled: SxProps;
} = {
  autoSelectOptionItem: {
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 40,
    padding: '0 19px',
    whiteSpace: 'pre-wrap',
  },
  autoSelectCheckboxOptionItem: {
    background: 'transparent',
    width: '100%',
    padding: '0 15px 0 0',
    color: themeColors.black,
    '&:hover, &.Mui-selected:hover, &.Mui-selected, &:hover p, &.Mui-selected:hover p, &.Mui-selected p, &:hover span, &.Mui-selected:hover span, &.Mui-selected span':
      {
        fontWeight: 'bold',
      },
    whiteSpace: 'nowrap',
  },
  defaultOption: {
    '&:hover': {
      backgroundColor: 'rgba(112, 47, 138, 0.08) !important',
      color: themeColors.black,
    },

    '&[aria-selected="true"]': {
      backgroundColor: `${themeColors.purplePrimary} !important`,
      color: themeColors.white,
      '&:hover': {
        backgroundColor: themeColors.purplePrimary,
        color: themeColors.white,
      },
    },
  },
  defaultGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingRight: '20px',
    '& .MuiAutocomplete-option .MuiAutocomplete-option:hover, .MuiAutocomplete-option[aria-selected="true"]:hover':
      {
        backgroundColor: themeColors.white,
      },
  },
  optionSelected: {
    color: themeColors.grey40,
  },
  paper: {
    '& .MuiAutocomplete-paper': {
      margin: 0,
      backgroundColor: themeColors.white,
      '& .MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: themeColors.white,
      },
      boxShadow: 'none',
    },
  },
  inputRoot: {
    '& .MuiAutocomplete-inputRoot': {
      flexDirection: 'column',
      alignItems: 'start',
      '& .MuiAutocomplete-input': {
        width: '100%',
      },
    },
    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      top: 0,
    },
  },
  popper: {
    '& 	.MuiAutocomplete-popper': {
      padding: '13px 0',
      border: `solid 1px ${themeColors.greyPrimary}`,
      backgroundColor: themeColors.white,
      '&[x-placement="bottom"]': {
        borderTop: 'none',
      },
      '&[x-placement="top"]': {
        borderBottom: 'none',
      },
    },
  },
  listbox: {
    '& .MuiAutocomplete-listbox': {
      margin: 0,
      padding: 0,
      border: 'none',
      '& li': {
        padding: 0,
        height: 42,
        '&[data-focus="true"]': {
          background: 'transparent',
        },
        '& .MuiIconButton-label': {
          width: 24,
          height: 24,
        },
      },
    },
  },
  option: {
    '& .MuiAutocomplete-option': {
      '&[aria-selected="true"]': {
        color: themeColors.grey40,
      },
    },
  },
  devider: {
    margin: '12px 0',
    height: '2px',
    backgroundColor: themeColors.grey20,
  },
  optionSubtitle: {
    color: themeColors.grey120,
    textOverflow: 'ellipsis',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
};

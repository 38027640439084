import React, { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';

import { InfoModal } from 'components/info-modal';
import { convertSxToThemeType } from 'utils';

import { styles } from './styles';
interface Props {
  title?: React.ReactNode;
  mb?: number;
  mt?: number;
  ml?: number;
  sx?: SxProps;
  testId?: string;
  divide?: boolean;
  infoModalContent?: ReactElement | null;
}

const FilterRow: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  title,
  testId,
  mt = 4,
  mb = 4,
  ml,
  sx = {},
  divide = true,
  infoModalContent,
  ...rest
}) => {
  const wrapperSx = convertSxToThemeType([styles.wrapper, sx]);

  return (
    <Box {...rest} mt={mt} mb={mb} ml={ml} data-testid={testId} sx={wrapperSx}>
      <Box width={233} minWidth={233} display="flex" alignItems="flex-start" pt="15px">
        <Typography variant="h4" color="textPrimary">
          {title}
        </Typography>
      </Box>
      {children}
      {infoModalContent && (
        <Box ml={1} sx={styles.infoModal}>
          <InfoModal>{infoModalContent}</InfoModal>
        </Box>
      )}
    </Box>
  );
};

export { FilterRow };

import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '@mui/material';

import { TEST_ID } from './constants';
import { styles } from './styles';

interface UseItemsOverflow<TItem> {
  itemsToRender: TItem[];
  addSymbolToItem: (index: number) => string;
  expandButton: boolean | React.ReactElement;
}

export const useItemsOverflow = <TItem,>({
  items,
  maxItemsCount,
}: {
  items: TItem[];
  maxItemsCount: number;
}): UseItemsOverflow<TItem> => {
  const [isExpanded, setIsExpanded] = useState(false);

  const hiddenItemsCount = items.length - maxItemsCount;
  const shouldBeExpandable = hiddenItemsCount > 0;

  const itemsToRender =
    shouldBeExpandable && !isExpanded ? [...items].splice(0, maxItemsCount) : items;

  const handleToggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  const addSymbolToItem = (index: number) => {
    if (index !== itemsToRender.length - 1) {
      return ',';
    }
    if (shouldBeExpandable && !isExpanded) {
      return '...';
    }

    return '';
  };

  const buttonTextId = isExpanded ? 'showLess' : 'showMore';

  const expandButton = shouldBeExpandable && (
    <Box
      component="span"
      sx={styles}
      data-testid={TEST_ID.toggleButton}
      onClick={handleToggleExpanded}
    >
      <FormattedMessage id={buttonTextId} values={{ count: hiddenItemsCount }} />
    </Box>
  );
  return { itemsToRender, expandButton, addSymbolToItem };
};

import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { themeColors } from 'theme/themeColors';

const DatalabIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon className="hover-disabled" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path fill={themeColors.purplePrimary} d="M9.603 9.579h5.653V0H9.603z" />
      <path
        fill={themeColors.redPrimary}
        d="m9.547 19.144-2.729 4.889h11.26l2.831-4.89z"
      />
      <path fill={themeColors.pink} d="m9.603 9.436 5.596 9.708h5.71l-5.653-9.708z" />
      <path
        fill={themeColors.yellowPrimary}
        d="m6.818 24.033 5.6-9.742-2.815-4.855L4 19.144z"
      />
    </g>
  </SvgIcon>
);

export default DatalabIcon;

export const queryKeys = {
  getDeal: 'get_deal',
  getDeals: 'get_deals',
  getUser: 'get_user',
  getUsers: 'get_users',
  getUserByToken: 'get_user_by_token',
  getAgencies: 'get_agencies',
  getAgency: 'get_agency',
  getAdvertiser: 'get_advertiser',
  getAdvertiserAudiences: 'get_advertiser_audiences',
  getShareKeyAudiences: 'get_share_key_audiences',
};

import { IntlShape } from 'react-intl';

export type ErrorResponse = {
  code: ErrorCodes;
  message?: string;
};

export enum ErrorCodes {
  DUPLICATE = 'DUPLICATE',
  NOT_FOUND = 'NOT_FOUND',
  NOT_VALID = 'NOT_VALID',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  UNKNOWN = 'UNKNOWN',
}

export const mapErrorCodeToError = (intl: IntlShape, errorCode: ErrorCodes) => {
  switch (errorCode) {
    case ErrorCodes.DUPLICATE: {
      return intl.formatMessage({
        id: 'advertiser.new.datalab.audiences.validation.duplicate',
      });
    }
    case ErrorCodes.NOT_VALID: {
      return intl.formatMessage({
        id: 'advertiser.new.datalab.audiences.validation.not.valid',
      });
    }
    case ErrorCodes.NOT_FOUND: {
      return intl.formatMessage({
        id: 'advertiser.new.datalab.audiences.api.validation.not.found',
      });
    }
    case ErrorCodes.CONNECTION_ERROR: {
      return intl.formatMessage({
        id: 'advertiser.new.datalab.audiences.api.validation.connection.error',
      });
    }
    default: {
      return intl.formatMessage({
        id: 'advertiser.new.datalab.audiences.api.unknown.error',
      });
    }
  }
};

export function isValidShareKey(key: string): boolean {
  return /^[a-zA-Z0-9]{32}$/.test(key);
}

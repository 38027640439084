import { QueryFunctionContext } from 'react-query';

import { Advertiser } from './types';

import { Api } from '../api';
import { apiEndpoints } from '../endpoints';

export async function getAdvertiser({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<Advertiser> {
  const [, id] = queryKey;
  const { data } = await Api.get<Advertiser>(apiEndpoints.advertiserGet({ id }));

  return data;
}

export async function editAdvertiser(advertiser: Advertiser): Promise<void> {
  await Api.post(apiEndpoints.advertiserEdit({ id: advertiser.id }), advertiser);
}

export async function deleteAdvertiser(advertiserId: string): Promise<void> {
  await Api.delete(apiEndpoints.advertiserDelete({ id: advertiserId }));
}

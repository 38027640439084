import React from 'react';
import { useIntl } from 'react-intl';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import DatalabIcon from 'assets/icons/datalab/DatalabIcon';
import { TextOverflowTooltip } from 'components/text-overflow-tooltip';

import { DatalabAudienceSelection } from '../../../api/deals/types';
import { ExtendedSelectData } from '../../form/fields/autocomplete-tags/AutocompleteTagsLocal';

interface Props {
  option: ExtendedSelectData<DatalabAudienceSelection>;
}

export function AudienceTagLabel({ option }: Props) {
  const intl = useIntl();
  const audience = option.properties!.audience;

  const audienceName = `${intl.formatMessage({
    id: `audience.datalab.type.${audience.type}`,
  })} ${audience.name}`;

  return (
    <Box display="flex" alignItems="center" pr={1}>
      <Box maxWidth={240}>
        <TextOverflowTooltip value={audienceName} />
      </Box>
      <DatalabIcon sx={{ ml: 1, width: '20px' }} />
      {Boolean(audience.reach) && (
        <Typography variant="h6" sx={{ ml: 1, lineHeight: 1.5 }}>
          {intl.formatNumber(audience.reach, { notation: 'compact' })}
        </Typography>
      )}
    </Box>
  );
}

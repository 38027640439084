import { AxiosError } from 'axios';
import { useCallback } from 'react';
import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  UseQueryOptions,
} from 'react-query';

import { ErrorCodes, ErrorResponse, isValidShareKey } from './utils';

import { getShareKeyAudiences } from '../../api/datalab';
import { GetAudiencesResult } from '../../api/datalab/types';
import { queryKeys } from '../../api/queryKeys';

export function useShareKeyAudiences(
  shareKey: string,
  options: UseQueryOptions<
    GetAudiencesResult,
    unknown,
    GetAudiencesResult,
    [string, string]
  >
) {
  return useQuery(
    [queryKeys.getShareKeyAudiences, shareKey],
    ({ queryKey }: QueryFunctionContext<[string, string]>) =>
      getShareKeyAudiences({ shareKey: queryKey[1] }),
    options
  );
}

export function useShareKeyAudiencesValidation() {
  const { mutate, isLoading, isSuccess } = useMutation(getShareKeyAudiences);
  const validate = useCallback(
    (shareKey: string): Promise<GetAudiencesResult> => {
      return new Promise((resolve, reject) => {
        if (!isValidShareKey(shareKey)) {
          reject(ErrorCodes.NOT_VALID);
          return;
        }

        // TODO test slow connection add
        mutate(
          { shareKey },
          {
            onSuccess: (data) => {
              resolve(data);
            },
            onError: (error) => {
              reject(
                ((error as AxiosError).response?.data as ErrorResponse)?.code ??
                  ErrorCodes.UNKNOWN
              );
            },
          }
        );
      });
    },
    [mutate]
  );

  return {
    isSuccess,
    isLoading,
    validate,
  };
}

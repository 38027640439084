import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import { Dialog } from 'components/dialog2';
import { ModalVariants } from 'components/dialog2/types';
import { Icon } from 'components/icon';

import { DatalabAudiencesFilter } from './DatalabAudiencesFilter';
import { InExcludeLabel } from './InExcludeLabel';
import { styles } from './styles';
import { getFilterValue } from './utils';

import { Advertiser } from '../../../api/advertisers/types';
import { DatalabAudienceSelection } from '../../../api/deals/types';
import { InfoRow } from '../../elements/info-row';
import { FilterRow } from '../../filter-row';
import { ReadOnlyToggleField } from '../../form/fields/read-only-toggle-field';
import { Tooltip } from '../../tooltip/Tooltip';

interface Props {
  datalabAudiences: DatalabAudienceSelection[] | null | undefined;
  onChange: (audiences: DatalabAudienceSelection[]) => void;
  isManageable: boolean;
  advertiser?: Advertiser | null;
}

const DatalabAudiencesDialog: React.FC<Props> = ({
  datalabAudiences,
  advertiser,
  isManageable,
  onChange: onChangeAudiences,
}) => {
  const intl = useIntl();
  const [value, onChange] = useState<DatalabAudienceSelection[]>(datalabAudiences ?? []);
  const onSubmit = () => {
    onChangeAudiences(value);
  };
  const onCancel = () => {
    onChange(datalabAudiences ?? []);
  };

  const filterValue = React.useMemo(
    () => getFilterValue(datalabAudiences ?? []),
    [datalabAudiences]
  );

  return (
    <FilterRow
      title={intl.formatMessage({ id: 'deal.form.item.datalabAudiences' })}
      mt={3}
      mb={3}
      infoModalContent={
        isManageable ? (
          <Box width={450}>
            <InfoRow
              title={intl.formatMessage({
                id: 'deal.form.item.datalabAudiences.info.title',
              })}
              description={intl.formatMessage({
                id: 'deal.form.item.datalabAudiences.info.description',
              })}
            />
          </Box>
        ) : undefined
      }
    >
      <Grid container justifyContent="flex-start" alignItems="center">
        <ReadOnlyToggleField
          editable={isManageable}
          value={
            <Box display="flex" alignItems="center">
              <InExcludeLabel datalabAudiences={datalabAudiences} />
            </Box>
          }
        >
          <Box display="flex" alignItems="center">
            <InExcludeLabel datalabAudiences={datalabAudiences} />
          </Box>
          <Dialog
            mode={ModalVariants.secondary}
            width={650}
            triggerEl={({ onClick }) => (
              <Tooltip
                title={
                  advertiser
                    ? ''
                    : intl.formatMessage({
                        id: 'deal.form.item.datalabAudiences.edit.missingAdvertiser',
                      })
                }
                size="small"
              >
                <span>
                  <IconButton
                    aria-label="more"
                    aria-haspopup="true"
                    sx={styles.editIcon}
                    size="large"
                    disabled={!advertiser}
                    onClick={onClick}
                  >
                    <Icon name="edit" width={24} height={24} />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          >
            {({ onDialogClose }) => (
              <DatalabAudiencesFilter
                value={value}
                advertiser={advertiser!}
                onChange={onChange}
                onSubmit={onSubmit}
                onCancel={onCancel}
                onDialogClose={onDialogClose}
              />
            )}
          </Dialog>
        </ReadOnlyToggleField>
      </Grid>
    </FilterRow>
  );
};

export { DatalabAudiencesDialog };

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Grid, { GridProps } from '@mui/material/Grid';

import DatalabIcon from 'assets/icons/datalab/DatalabIcon';

import { DatalabAudience } from '../../api/datalab/types';

interface Props {
  datalabAudiences: DatalabAudience[];
  containerProps?: GridProps;
}

export const DatalabAudienceInfo: React.FC<Props> = ({
  datalabAudiences,
  containerProps,
}) => {
  const intl = useIntl();
  const groupNames = [...new Set(datalabAudiences.map((a) => a.groupName))];

  return (
    <Grid item container xs={12} mt={3} rowGap={1} {...containerProps}>
      <Grid item xs={4}>
        <FormattedMessage id="advertiser.new.datalab.audiences.group.title" />
      </Grid>
      <Grid item container xs={8} alignItems="center">
        <DatalabIcon sx={{ mr: 1 }} />
        {groupNames.length
          ? groupNames.join(', ')
          : intl.formatMessage({
              id: 'advertiser.new.datalab.audiences.no.audiences',
            })}
      </Grid>
      <Grid item xs={4}>
        <FormattedMessage id="advertiser.new.datalab.audiences.audiences.title" />
      </Grid>
      <Grid item container xs={8} alignItems="center">
        <FormattedMessage
          id="advertiser.new.datalab.audiences.audiences"
          values={{
            total: datalabAudiences.length,
          }}
        />
      </Grid>
    </Grid>
  );
};

import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DialogContainer, DialogFooter, DialogTitle } from 'components/dialog2';
import { Reason } from 'components/dialog2/types';
import { LoadingOverlay } from 'components/loading-overlay';

import { AudienceAutocompleteOption } from './AudienceAutocompleteOption';
import { AudienceTagLabel } from './AudienceTagLabel';
import { useAdvertiserAudiences } from './useAdvertiserAudiences';
import { getAudienceOptions, getAudienceValues, setAudiencesForRelation } from './utils';

import { Advertiser } from '../../../api/advertisers/types';
import {
  DatalabAudienceSelection,
  DatalabAudienceSelectionRelation,
} from '../../../api/deals/types';
import { ContainerWithTitle } from '../../elements/container-with-title';
import {
  AutocompleteTagsLocal,
  ExtendedSelectData,
} from '../../form/fields/autocomplete-tags/AutocompleteTagsLocal';

interface Props {
  value: DatalabAudienceSelection[];
  onChange: (value: DatalabAudienceSelection[]) => void;
  advertiser?: Advertiser;
  onSubmit: () => void;
  onCancel: () => void;

  onDialogClose?(event?: Record<string, unknown>, reason?: Reason): void;
}

const DatalabAudiencesFilter: React.FC<Props> = ({
  value,
  advertiser,
  onChange,
  onSubmit,
  onCancel,
  onDialogClose,
}) => {
  const intl = useIntl();
  const { data, isLoading } = useAdvertiserAudiences(advertiser);
  const isEmpty = !isLoading && !data?.audiences.length;
  const handleSubmit = () => {
    onSubmit();
    onDialogClose?.();
  };
  const handleCancel = () => {
    onCancel();
    onDialogClose?.();
  };
  const optionsRenderer = useCallback(
    (props: {
      option: ExtendedSelectData<DatalabAudienceSelection>;
      inputValue: string;
    }) => <AudienceAutocompleteOption {...props} />,
    []
  ) as (props: unknown) => React.ReactElement;
  const tagLabelRenderer = useCallback(
    (props: { option: ExtendedSelectData<DatalabAudienceSelection> }) => (
      <AudienceTagLabel {...props} />
    ),
    []
  ) as (props: unknown) => React.ReactElement;

  const includedValues: ExtendedSelectData<DatalabAudienceSelection>[] = useMemo(
    () => getAudienceValues(value, DatalabAudienceSelectionRelation.INCLUDE),
    [value]
  );
  const excludedValues: ExtendedSelectData<DatalabAudienceSelection>[] = useMemo(
    () => getAudienceValues(value, DatalabAudienceSelectionRelation.EXCLUDE),
    [value]
  );
  const includedOptions: ExtendedSelectData<DatalabAudienceSelection>[] = useMemo(
    () =>
      getAudienceOptions(
        data?.audiences ?? [],
        value,
        DatalabAudienceSelectionRelation.INCLUDE
      ),
    [value, data]
  );
  const excludedOptions: ExtendedSelectData<DatalabAudienceSelection>[] = useMemo(
    () =>
      getAudienceOptions(
        data?.audiences ?? [],
        value,
        DatalabAudienceSelectionRelation.EXCLUDE
      ),
    [value, data]
  );
  const onIncludedChange = (
    selections: ExtendedSelectData<DatalabAudienceSelection>[]
  ) => {
    onChange(
      setAudiencesForRelation(selections, value, DatalabAudienceSelectionRelation.INCLUDE)
    );
  };
  const onExcludedChange = (
    selections: ExtendedSelectData<DatalabAudienceSelection>[]
  ) => {
    onChange(
      setAudiencesForRelation(selections, value, DatalabAudienceSelectionRelation.EXCLUDE)
    );
  };

  return (
    <DialogContainer
      header={
        <DialogTitle
          headerLabel="deal.datalabAudience.dialog.title"
          subHeaderLabel="deal.datalabAudience.dialog.description"
          handleClose={handleCancel}
        />
      }
      footer={
        <DialogFooter
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          submitButtonLabel="deal.datalabAudience.dialog.submit"
        />
      }
    >
      {isLoading && <LoadingOverlay />}
      {isEmpty && <FormattedMessage id="deal.datalabAudience.dialog.empty" />}
      {!isLoading && !isEmpty && (
        <>
          <ContainerWithTitle
            title={intl.formatMessage({
              id: 'deal.datalabAudience.dialog.filters.include',
            })}
            mt={2}
            mb={2}
          >
            <AutocompleteTagsLocal
              placeholder="deal.datalabAudience.dialog.filters.include.placeholder"
              setValue={onIncludedChange}
              value={includedValues}
              options={includedOptions}
              optionsRenderer={optionsRenderer}
              tagLabelRenderer={tagLabelRenderer}
            />
          </ContainerWithTitle>
          <ContainerWithTitle
            title={intl.formatMessage({
              id: 'deal.datalabAudience.dialog.filters.exclude',
            })}
            mt={2}
            mb={2}
          >
            <AutocompleteTagsLocal
              setValue={onExcludedChange}
              value={excludedValues}
              placeholder="deal.datalabAudience.dialog.filters.exclude.placeholder"
              options={excludedOptions}
              optionsRenderer={optionsRenderer}
              tagLabelRenderer={tagLabelRenderer}
            />
          </ContainerWithTitle>
        </>
      )}
    </DialogContainer>
  );
};

export { DatalabAudiencesFilter };

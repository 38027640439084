import React from 'react';

import { SvgIcon } from '@mui/material';

type Props = {
  color: string;
};
const StatusIcon: React.FC<Props> = ({ color }) => (
  <SvgIcon>
    <circle fill={color} cx="12" cy="12" r="4" />
  </SvgIcon>
);

export { StatusIcon };

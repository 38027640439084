import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Box, Typography } from '@mui/material';

import { findAgency } from './util';

import { Agency } from '../../../api/agencies/types';
import { Deal, DealAspects } from '../../../api/deals/types';
import { useUser } from '../../../utils/keycloak/KeycloakProvider';
import { DealEntry } from '../../deals-form/util';
import { FilterRow } from '../../filter-row';
import { SelectField } from '../../form/fields/select-field';
import { styles } from '../styles';

export type Props = {
  deal: DealEntry['deal'];
  canEditAgency: boolean;
  onChange(
    deal: Optional<DealAspects> & Pick<Deal, 'dealId'> & { ownedBy?: Agency['id'] }
  ): void;
};

export const DealAgencySelect: React.FC<Props> = ({ deal, onChange, canEditAgency }) => {
  const intl = useIntl();
  const user = useUser();
  const agencies = useMemo(() => user?.agencies ?? [], [user]);
  const canSelectAgencyOptions = (user?.agencies?.length ?? 0) > 1 || !deal.agency;
  const currentValue = useMemo(() => findAgency(deal, agencies), [agencies, deal]);

  return (
    <FilterRow title={intl.formatMessage({ id: 'deal.form.item.agency' })} mt={3} mb={3}>
      {canEditAgency && canSelectAgencyOptions ? (
        <SelectField
          displayEmpty
          fullWidth
          value={currentValue ?? ''}
          options={agencies.map((a) => ({ value: a, label: a.name }))}
          placeholder={currentValue ? undefined : (deal.agency ?? undefined)}
          variant="outlined"
          onChange={(e) => {
            const agency = e.target.value as Agency;

            onChange({
              ...deal,
              agency: agency?.dealNameAlias ?? agency?.name,
              ownedBy: agency?.id,
              buyer: null,
              advertiser: null,
              datalabAudiences: null,
            });
          }}
        />
      ) : (
        <Box sx={styles.fullWidth}>
          <Typography sx={styles.padding}>{deal.agency}</Typography>
        </Box>
      )}
    </FilterRow>
  );
};
